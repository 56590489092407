<template>
    <div class="main">
        <agent-info-comp></agent-info-comp>

        <div class="search">
            <el-select v-model="log.cashOrPoint" size="mini" placeholder="머니/포인트"
                       style="width:110px;margin-left: 5px">
                <el-option key="4" label="전체" :value="null">전체</el-option>
                <el-option key="5" label="머니내역" :value="agentConst.CashPointLog.LOG_CASHPOINT_CASH">머니내역</el-option>
                <el-option key="6" label="포인트내역" :value="agentConst.CashPointLog.LOG_CASHPOINT_POINT">포인트내역</el-option>
            </el-select>
            <el-date-picker size="mini" style="margin-left: 5px"
                            v-model="startDate"
                            align="right"
                            type="date"
                            placeholder="시작일 선택"
                            :picker-options="pickerOptions"
                            @change="setStartDate">
            </el-date-picker>
            <div style="padding-left: 2px;padding-right: 2px">~</div>
            <div class="block">
                <span class="demonstration"></span>
                <el-date-picker size="mini"
                                v-model="endDate"
                                align="right"
                                type="date"
                                placeholder="마감일 선택"
                                :picker-options="pickerOptions"
                                @change="setEndDate">
                </el-date-picker>
            </div>
            <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="log.byField"
                       placeholder="검색조건">
                <el-option :value="agentConst.User.SEARCH_BY_USERNAME" label="아이디"></el-option>
                <el-option :value="agentConst.User.SEARCH_BY_NICKNAME" label="닉네임"></el-option>
            </el-select>
            <el-input v-model="log.searchString" size="mini" style="width: 150px;margin-left: 5px"
                      placeholder="검색내용입력"></el-input>
            <el-button type="primary" @click="getLogList" size="mini" style="margin-left: 10px">검색</el-button>
        </div>

        <div class="data">
            <el-table
                    :data="logList"
                    style="width: 100%"
                    max-height="730"
                    border>
                <el-table-column
                        label="날짜"
                        width="130">
                    <template slot-scope="scope">
                        {{scope.row.createTime|datef('MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="머니 or 포인트"
                        width="110">
                    <template slot-scope="scope">
                        <span v-if="scope.row.cashOrPoint == agentConst.CashPointLog.LOG_CASHPOINT_CASH">머니</span>
                        <span v-if="scope.row.cashOrPoint == agentConst.CashPointLog.LOG_CASHPOINT_POINT">Point</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="전금액"
                        width="150">
                    <template slot-scope="scope">
                        {{scope.row.oldValue|comma}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="수량"
                        width="150">
                    <template slot-scope="scope">
                       <span class="text-blue"
                             v-if="scope.row.plusOrMinus == agentConst.CashPointLog.LOG_CASHPOINT_PLUS">+{{scope.row.amount | comma}}</span>
                        <span class="text-danger"
                              v-if="scope.row.plusOrMinus == agentConst.CashPointLog.LOG_CASHPOINT_MINUS">-{{scope.row.amount | comma}}</span>

                    </template>
                </el-table-column>
                <el-table-column
                        label="현재"
                        width="150">
                    <template slot-scope="scope">
                        {{scope.row.currentValue|comma}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="내용"
                        width="600">
                    <template slot-scope="scope">
                       <span :class="{'bg-skyblue':(scope.row.type == agentConst.CashPointLog.LOG_CASH_TYPE_MANAGER_ADDCASH
                       ||scope.row.type == agentConst.CashPointLog.LOG_CASH_TYPE_MANAGER_MINUSCASH
                       ||scope.row.type == agentConst.CashPointLog.LOG_POINT_TYPE_MANAGER_ADDPOINT
                       ||scope.row.type == agentConst.CashPointLog.LOG_POINT_TYPE_MANAGER_MINUSPOINT)}">{{scope.row.content}}</span>
                    </template>
                </el-table-column>

            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>

    import {Loading} from "element-ui";
    import {agentMixin} from "../../common/agent/agentMixin";
    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import {getLogCashPointList2, getUserLogCashPointList} from "../../network/agent/commonRequest";
    import agentConst from "../../common/agent/agentConst";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";
    import {RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "../../store/mutation-types";

    export default {
        name: "AgentUserCashPointLog",
        mixins: [agentMixin],
        components: {AgentInfoComp, PartnerSubSelectorComp},
        data() {
            return {
                startDate: '',
                endDate: '',
                pageNum: 1,
                pageSize: 50,
                pageTotal: 0,
                log: {},
                logList: [],
                totalAmount: 0,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '30일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', date);
                        }
                    }]
                },

            }
        },
        methods: {
            selectCashOrPoint() {
                this.getLogList()
            },

            getLogList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                this.log.startDate = this.startDate;
                this.log.endDate = this.endDate;
                getUserLogCashPointList(this.log, this.pageNum, this.pageSize).then(res => {
                    loadingInstance.close()
                    if (res.data.success) {
                        this.logList = res.data.data.result
                        this.pageTotal = res.data.data.pageTotal
                        this.totalAmount = res.data.data.totalAmount;

                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }


                })
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getLogList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getLogList();
            },
            setStartDate() {
                this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
            },
            setEndDate() {
                this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
            },
            setPath() {
                this.startDate = this.$moment().subtract(1, 'days').format('yyyy-MM-DD')
                this.endDate = this.$moment().format('yyyy-MM-DD')
            },
        },
        created() {
            if(this.$route.query.nickname){
                this.log.searchString = this.$route.query.nickname;
                this.log.byField = agentConst.User.SEARCH_BY_NICKNAME;
                this.getLogList()
            }
        },
    }
</script>

<style scoped>

</style>